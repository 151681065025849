<template>
    <div class="content md flex-box vertical">
        <div class="data-title">
            <div class="title-left flex-box">
                <span>共{{pagination.total}}条记录</span>
            </div>
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="月份">
                    <a-month-picker
                      :allow-clear="false"
                      :disabled-date="disabledDate"
                      v-model="form.month"
                      placeholder="请选择月份"
                      format="YYYY年M月"
                      value-format="YYYY-MM"
                      @change="getList"/>
                </a-form-model-item>
                <a-form-model-item label="">
                    <a-input-search allow-clear v-model="form.name" placeholder="输入法名搜索" style="width: 180px" @search="getList"/>
                </a-form-model-item>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList">查询</a-button>
                <a-button type="primary" @click="exportData" :loading="exporting">{{exporting ? '正在导出' : '导出月报'}}</a-button>
            </a-space>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    row-key="user_id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :expanded-row-keys="expandKeys"
                    @expandedRowsChange="expandedRowsChange"
                    @change="handleTableChange">
                <template v-slot:expandedRowRender="text">
                    <a-table
                      row-key="id"
                      :columns="innerColumns"
                      :loading="text.loading"
                      :data-source="text.detail || []"
                      :pagination="false">
                        <template v-slot:action="text, record">
                            <div class="row-btn">
                                <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
                            </div>
                        </template>
                    </a-table>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import {getTemple} from "../../common/js/storage";
    import {downLoadBlobFile, getDate} from "../../common/js/tool";

    export default {
        name: "BuddhismSubsidy",
        data() {
            return {
                loading: false,
                list: [],
                form: {},
                h: 500,
                columns: [
                    {title: '法名', dataIndex: 'user_id', customRender: text => <open-data type="userName" openid={text} />},
                    {title: '月份', dataIndex: 'create_time', customRender: text => getDate(text).getMonth() + 1 + "月"},
                    {title: '累计场次', dataIndex: 'fs_count'},
                    {title: '津贴金额', dataIndex: 'money'},
                ],
                innerColumns: [
                    {title: '时间', dataIndex: 'create_time'},
                    {title: '佛事名称', dataIndex: 'tempName'},
                    {title: '津贴金额', dataIndex: 'subsidyNum'},
                    {title: '操作', key: 'action', width: 120, scopedSlots: { customRender: 'action'}}
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                detail: null,
                visible: false,
                refundNum: 0,
                expandKeys: [],
                temp: null,
                exporting: false
            }
        },
        created() {
            // this.getBuddhaTemp();
            this.$set(this.form, 'month', new Date().pattern("yyyy-MM"));
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            disabledDate(current) {
                return current && current > moment().endOf('day');
            },
            exportData() {
                if(this.exporting) return;
                this.exporting = true;
                let fileName = `${this.form.month}单资数据`;
                this.$axios(this.getQuery(true), {noTempleFilter: true, responseType: 'blob'}).then(res => {
                    downLoadBlobFile(res, fileName)
                    this.exporting = false;
                }).catch(() => {
                    this.exporting = false;
                });
            },
            showDetail(record) {
                if(record.fs) {
                    this.$router.push({name: 'BuddhismDetail', params: {id: record.fs.id}});
                }
            },
            expandedRowsChange(keys) {
                let old = this.expandKeys;
                let add = keys.filter(key => old.indexOf(key) === -1);
                add.forEach(item => this.getDetailList(this.list.find(a => a.user_id == item)));
                this.expandKeys = keys;
            },
            getDetailList(record) {
                if(!record || record.detail || record.loading) return;
                this.$set(record, 'loading', true);
                let url = `/admin/fs-allowance-record?pageSize=1000&expand=fs&filter[user_id]=${record.user_id}`;
                this.$axios(url).then(res => {
                    let list = res.data;
                    list.forEach(item => {
                        item.tempName = item.fs ? item.fs.name : '--';
                        item.subsidyNum = `${item.money}元`
                    })
                    this.$set(record, 'detail', list);
                }).finally(() => {
                    this.$set(record, 'loading', false);
                });
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getQuery(ex) {
                let {current, pageSize} = this.pagination;
                let url = `/admin/fs-allowance-record/get-statics?page=${current}&pageSize=${pageSize}&temple_id=${getTemple()}`;
                let form = this.form;
                if(form.month) {
                    const start  = `${form.month}-01`;
                    const date = getDate(start);
                    date.setMonth(date.getMonth() + 1);
                    date.setDate(date.getDate() - 1);
                    const end = date.pattern("yyyy-MM-dd");
                    url += `&begin_time=${start}&end_time=${end}`;
                }
                if(form.name) {
                    url += `&name=${form.name}`;
                }
                if(ex) {
                    url += "&is_export=1";
                }
                return url;
            },
            getList() {
                if(this.loading) return;
                this.loading = true;
                this.$axios(this.getQuery(), {noTempleFilter: true}).then(res => {
                    this.dealList(res.data);
                    this.$set(this.pagination, 'total', res.total_count);
                }).finally(() => {
                    this.loading = false;
                });
            },
            dealList(list) {
                this.expandKeys = [];
                this.list = list;
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped>

</style>
